
.settings_card{
    background: #fff;
    border-radius: 12px;
    padding: 40px 20px;
}

.title{
    text-align: center;
    color: rgba(61, 56, 57, 0.7);
}

.input_container label {
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    font-family: 'BRFirma500';
  }

.input_style{
    border: 1px solid rgba(244, 125, 32, 0.1);
    border-radius: 5px;
    font-size: 14px;
    color: rgba(61, 56, 57, 0.8);
    width: 100%;
    padding: 14px 20px;
    background: #FBFBFB;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input_style:focus{
    border: 1px solid rgba(244, 125, 32, 0.1);
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
.input_style::placeholder{
    color:rgba(26, 55, 78, 0.5);
    font-size: 14px;
    font-family: 'BRFirma400';
}


  .btn_change{
    color: #fff;
    border-radius: 6px;
    background: #3D3839;
    padding: 14px 40px;
    transition: all 0.15s ease-in-out;
    width: auto;
    font-weight: 300;
  }

  .btn_change:hover{
    color: #fff;
    background: rgba(61, 56, 57, 0.9);
  }



/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {  
 .settings_card{
    background: #fff;
    border-radius: 12px;
    padding: 40px 80px;
    max-width: 48rem;
    margin: auto;
 }
}



/* large screens */
@media (min-width: 1536px) {
}