.NotFound_div {
    min-height: 100vh;
    position: relative;
  }
  
  .NotFound_homepage {
    font-weight: 400;
    font-size: 16px;
    color: #f47d20;
    padding-top: 16px;
    text-align: center;
  }
  
  .back2home_div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  
  .back2home_div:hover{
    text-decoration: none;
  }
  
  .back2home {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.396px;
    color: rgba(61, 56, 57, 0.7);
    cursor: pointer;
    margin-bottom: 0px;
  }
  
  .chevron_left_icon {
    color: rgba(61, 56, 57, 0.7);
    font-size: 34px;
    margin-top: -0.1%;
  }
  
  .footer img {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  
  /*----------------------------------------------- MEDIA QUERY SECTION-------------------------------------------------- */
  
  /* MEDIA QUERY FOR SMALL SCREENS AND TABLETS */
  @media (min-width: 280px) {
    .NotFound_img_div {
      padding-top: 25%;
      text-align: center;
      font-family: 'BRFirma400';
    }
  
    .NotFound_warning {
      font-weight: 400;
      font-size: 16px;
      padding: 0px 20px;
      color: rgba(61, 56, 57, 0.7);
    }
  
    .NotFound_homepage {
      font-weight: 400;
      font-size: 12px;
      color: #f47d20;
      padding-top: 16px;
    }
  
  
    .NotFound_img {
      width: 200px;
      height: 200px;
    }
  }
  
  /* MEDIA QUERY FOR TABLETS AND HIGHER  */
  @media (min-width: 768px) {
    .NotFound_img_div {
      padding-top: 4%;
      text-align: center;
      font-family: 'BRFirma400';
    }
  
    .NotFound_warning {
      font-weight: 400;
      font-size: 20px;
      color: rgba(61, 56, 57, 0.7);
      max-width: 26rem;
      margin: 0 auto;
      padding: 0px;
    }
  
    .footer img {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  
    .NotFound_img {
      width: 300px;
      height: 300px;
    }
  
    .NotFound_homepage {
      font-weight: 400;
      font-size: 16px;
      color: #f47d20;
      padding-top: 16px;
      text-align: center;
    }
  }
  