.profileCard{
    background: #FFFFFF;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
}

.firstHalf{
    border-right: none;
    padding: 20px;
    flex: 1;
}

.userImg{
    display: flex;
    justify-content: center;
}

.userImg img{
    border: 1px solid rgba(244, 142, 63, 0.2);
    width: 140px;
    height: 140px;
    border-radius: 50%;
}

.changeImg_div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.changeImg_div img{
    margin-right: 10px;
}

.changeImg_text{
    margin-bottom: 0px;
    color: #F47C20;
    font-family: 'BRFirma400';
    font-size: 14px;
    cursor: pointer;
}

.profileHr{
    background:#ECECEC;
    max-width: 200px;
    margin: auto;
    height: 1px;
}

.profileName_Div{
   text-align: center;
}

.name{
    color: #3D3839;
    font-weight: 600;
    margin-bottom: 0px;
}

.date{
    color: #989898;
    font-family: 'BRFirma400';
    font-size: 14px;
}

.tradeCount{
    text-align: center;
    color: #3D3839;
    font-weight: 500;
}

.tradeInfo{
    text-align: center;
    color: #F47D20;
    font-size: 14px;
    font-family: 'BRFirma400';
}

.secondHalf{
    padding: 20px;
    flex: 1;
}


.input_container label {
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    font-family: 'BRFirma500';
  }
  
  .input_style {
    -webkit-appearance: none;
    appearance: none;
    background: rgba(61, 56, 57, 0.1);
    border: none;
    border-radius: 6px;
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    padding: 15px 20px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    font-family: 'BRFirma500';
  }
  

  .input_style:disabled {
    -webkit-appearance: none;
    appearance: none;
    color: rgba(61, 56, 57, 0.8);
    opacity: 1;
}

  .input_style:focus {
    background: white;
    box-shadow: none;
    outline: 0 none;
    border: 1px solid rgba(61, 56, 57, 0.4);
  }
  
  .input_style::placeholder {
    font-size: 14px;
    color: rgba(61, 56, 57, 0.8);
    opacity: 0.5;
  }

  .btn_submit{
    color: #fff;
    border-radius: 6px;
    background: #3D3839;
    padding: 14px 40px;
    transition: all 0.15s ease-in-out;
    width: 100%;
    font-weight: 300;
  }

  .btn_submit:hover{
    color: #fff;
    background: rgba(61, 56, 57, 0.9);
  }




/* MEDIA QUERY FOR  TABLETS  */
@media (min-width: 768px) {

}



/* MEDIA QUERY FOR LARGE SCREENS AND HIGHER THAN TABLETS  */
@media (min-width: 1024px) {
    .profileCard{
        background: #FFFFFF;
        border-radius: 10px;
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: row;
    }

    .firstHalf{
        border-right: 1px solid #ECECEC;
        padding: 40px;
        flex: 1;
    }

    .secondHalf{
        padding: 40px;
        flex: 1;
    }
    

}


/* large screens */
@media (min-width: 1536px) {
    
}
