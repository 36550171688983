.btn_submit{
    padding: 14px 30px;
    background: #3D3839;
    border: 1px solid #3D3839;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    font-family: 'Montserrat';
  }
  
  .btn_submit:hover{
    color: #fff;
    background: rgba(61, 56, 57, 0.9)
  }
  
  
  /*----------------------------------------------- MEDIA QUERY SECTION--------------------------------------------------*/
  
  /* MEDIA QUERY FOR SMALL SCREENS AND TABLETS */
  @media (min-width: 280px) {
    /* I used min-width of 280px instead of 320px because of phones that have smaller width like galaxy fold */
  
  }
  
  /* MEDIA QUERY FOR LARGE SCREENS AND HIGHER THAN TABLETS  */
  @media (min-width: 1024px) {
  
  
  }
  