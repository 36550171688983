

.tradeDiv{
    background: #fff;
    border-radius: 12px;
    padding: 10px;
}

.trade_filter_container{
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
}

.transaction_search{
    display: flex;
}

.search_container{
    width: 100%;
}

.input_container label {
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    font-family: 'BRFirma500';
  }


.search_input_style{
    border: 1px solid rgba(244, 125, 32, 0.2);
    border-radius: 5px;
    font-size: 14px;
    color: rgba(61, 56, 57, 0.8);
    width: 100%;
    padding: 14px 20px;
    background: #fff;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.search_input_style:hover{
    border: 1px solid rgba(244, 125, 32, 0.2);
    background: white;
    box-shadow: none;
    outline: 0 none;  
}

.input_style{
    border: 1px solid rgba(244, 125, 32, 0.1);
    border-radius: 5px;
    font-size: 14px;
    color: rgba(61, 56, 57, 0.8);
    width: 100%;
    padding: 14px 20px;
    background: #FBFBFB;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input_style:focus{
    border: 1px solid rgba(244, 125, 32, 0.1);
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
.input_style::placeholder{
    color:rgba(26, 55, 78, 0.5);
    font-size: 14px;
    font-family: 'BRFirma400';
}


.btn_filter{
    color: #fff;
    border-radius: 6px;
    background: #3D3839;
    padding: 14px 30px;
    transition: all 0.15s ease-in-out;
    width: 100%;
    font-weight: 300;
}

.btn_filter:hover{
    color: #fff;
    background: rgba(61, 56, 57, 0.9);
}

.tradeCard{
    background: #FFFFFF;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
}

.firstHalf{
    border-right: none;
    padding: 20px;
    flex: 1;
}

.secondHalf{
    padding: 20px;
    flex: 1;
}

.cardTitle{
    color: rgba(61, 56, 57, 0.7);
    font-weight: 500;
}


.info_title{
    color: rgba(61, 56, 57, 0.7);
    font-weight: 600;
    font-size: 15px;
}

.info_subtitle{
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma400';
    font-size: 14px;
}

.pending_badge{
    color: #FFD66B;
    background: rgba(255, 214, 107, 0.1);
    border-radius: 100px;
    padding: 5px 20px;
}

.success_badge{
    color: #9CC031;
    background: rgba(156, 192, 49, 0.1);
    border-radius: 100px;
    padding: 5px 20px;   
}

.failed_badge{
    color: #FF8F6B;
    background: rgba(255, 143, 107, 0.1);
    border-radius: 100px;
    padding: 5px 20px; 
}


.suspend_btn{
    color: #fff;
    border-radius: 6px;
    background: #F47D20;
    padding: 14px 30px;
    transition: all 0.15s ease-in-out;
    font-weight: 300;
}

.suspend_btn:hover{
    color: #fff;
    background: rgba(244, 125, 32, 0.9);
}


.restore_btn{
    color: #fff;
    border-radius: 6px;
    background: #3D3839;
    padding: 14px 30px;
    transition: all 0.15s ease-in-out;
    font-weight: 300;
}

.restore_btn:hover{
    color: #fff;
    background: rgba(61, 56, 57, 0.9);
}



.accountDetailsDiv {
    display: block;
  }

  .accountDetailsIcon {
    margin-right: 20px;
  }

.accountDiv {
    background: #fef6ee;
    border: 0.2px solid #ececec;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .accountInfo {
    display: flex;
  }
  
  .accountIcon {
    background: #fcfcfc;
    border-radius: 10px;
    width: 58px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
  
  .accountNm {
    color: #3d3839;
    font-family: 'BRFirma600';
    max-width: 10rem;
  }
  
  .accountNo {
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma500';
    font-size: 14px;
  }
  
  .bankTitle {
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma500';
    font-size: 14px;
  }
  



/* MEDIA QUERY FOR  TABLETS  */
@media (min-width: 768px) {

}



/* MEDIA QUERY FOR LARGE SCREENS AND HIGHER THAN TABLETS  */
@media (min-width: 1024px) {
    .trade_filter_container{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 25px 20px 0 0;
    }
    .search_input_style{
        width: 500px;
    }
    .tradeCard{
        background: #FFFFFF;
        border-radius: 10px;
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: row;
    }
    .firstHalf{
        border-right: 1px solid #ECECEC;
        padding: 40px;
        flex: 1;
    }

    .secondHalf{
        padding: 40px;
        flex: 1;
    }
    .accountDetailsDiv {
        display: flex;
        align-items: center;
      }
    .accountDiv {
        background: #fef6ee;
        border: 0.2px solid #ececec;
        border-radius: 15px;
        padding: 20px;
        display: flex;
        flex-direction: row;
        min-width: 400px;
      }
    
      .accountNm {
        color: #3d3839;
        font-family: 'BRFirma600';
        max-width: 16rem;
      }

}


/* large screens */
@media (min-width: 1536px) {
    
}