.modal{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    background-color: rgba(0,0,0,0.5);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}
.modal-close-icon{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px 20px 0px 20px;
}

.modal.show{
    opacity: 1;
    pointer-events: visible;
}

.modal.enter-done{
    opacity: 1;
    pointer-events: visible;
    overflow-x: hidden;
    overflow-y: auto;
}

.modal.exit{
    opacity: 0;
}

.modal.enter-done .modal-content{
    transform: translateY(0);
}

.modal.exit .modal-content{
    transform: translateY(-200px);
}

.modal-content{
    width: auto;
    background-color: #fff;
    transform: translateY(-200px);
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
    border: 1px solid #fff;
    margin: .5rem;
    /* filter: drop-shadow(0px 30px 70px rgba(26, 66, 149, 0.25)); */
}

.modal.show .modal-content{
    transform: translateY(0);
}

.modal-title{
    margin: 0;
    color: rgba(61, 56, 57, 0.7);
    text-align: center;
    font-size: 18px;
}

.modal-content-layout{
    padding: 25px 30px;
}


.logout_icon_div{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF0F1;
    width: 50px;
    height: 50px;
    border-radius: 50%;  
}


.pop_title{
    color: #333333;
    font-weight: 600;
    font-size: 16px;
    margin-top: 15px;
}

.pop_subtitle{
    color: #808080;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
}

.logout-cta{
    display: flex;
    margin-top: 30px;
    justify-content: center;
}

.cancel-btn{
    padding: 12px 40px;
    background: #F0F0F0;
    border-radius: 5px;
    color: #808080;
    font-size: 14px;
    font-weight: 500;
    margin-right: 20px;
    min-width: 140px;
}

.cancel-btn:hover{
    color: #808080;
    background: rgba(240, 240, 240, 0.9);
}

.btn-action{
    padding: 12px 40px;
    background:#A3000E;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    min-width: 140px;  
}

.btn-action:hover{
    color: #fff; 
    background: rgba(163, 0, 14, 0.9);
}



.modal-body{
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
    
}


/* sreens higher than tablets */
@media (min-width: 1024px) {
  .modal-content{
    max-width: 500px;
     margin: 1.75rem auto;
  }
  .modal-close-icon{
    display: flex;
    align-items: center;
    justify-content: space-between;
     cursor: pointer;
       padding: 10px 20px 0px 20px;
  }
}

/* large screens */
@media (min-width: 1536px) {
   
}