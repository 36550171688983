* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

/* custom fonts- BR Firma */

/* When you want to use the BRFirma font, just define the font family in your style with the name defined below
 for each weight, if not defined, the default that will be used will be Montserrat

  e.g say you have a classname called btn-see and you need to use the br firma class, you can do like this
 .btn-see{
   font-family: 'BRFirma400' -- for font-weight 400 according to the figma design
 }
 
 */

@font-face {
  font-family: 'BRFirma400';
  src: local('BRFirmaRegular'),
    url(./fonts/br-firma-cufonfonts/BRFirmaRegular.otf);
}

@font-face {
  font-family: 'BRFirma500';
  src: local('BRFirmaMedium'),
    url(./fonts/br-firma-cufonfonts/BRFirmaMedium.otf);
}

@font-face {
  font-family: 'BRFirma600';
  src: local('BRFirmaSemiBold'),
    url(./fonts/br-firma-cufonfonts/BRFirmaSemiBold.otf);
}

/* End of custom fonts ----  */

body {
  font-family: 'Montserrat', sans-serif;
}

.homeBg {
  background: #fbf7f4;
}

/* my container class instead of the default bootstrap container class, so always use this as the container name for this project */
.contain {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}


.rdt_TableHeader {
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.rdt_TableHeadRow{
  color: #3D3839 !important;
  font-family: 'BRFirma400';
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.pending_icon{
  color: #FFD66B;
  background: transparent;
  border-radius: 100px;
  padding: 5px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'BRFirma400';
}


.completed_icon{
  color: #9CC031;
  background: transparent;
  border-radius: 100px;
   padding: 5px 20px;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'BRFirma400';
}

.failed_icon{
color: #FF8F6B;
  background: transparent;
  border-radius: 100px;
   padding: 5px 20px;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  font-family: 'BRFirma400'; 
}


.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.btn-view{
  border: 1px solid #3D3839;;
  color:  #3D3839;
  transition: all .15s ease-in;
  font-family: 'BRFirma400';
}

.btn-view:hover{
  background:  #3D3839;;
  color: white;
}

.pincode-input-container .pincode-input-text{
  border-radius: 8px;
  margin-right: 15px !important;
  margin-bottom: 5px !important;
  appearance: none;
}


.pincode-input-container .pincode-input-text:last-child{
  margin-right: 0px !important;
}

.react-confirm-alert-body{
  font-size: 14px;
  font-family: 'BRFirma400' !important;
}

.react-confirm-alert-body > h1{
  font-size: 1.6em;
}


@media (min-width: 640px) {
  .contain {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .contain {
    padding-left: 3rem;
    padding-right: 3rem;
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .contain {
    max-width: 1024px;
  }
  .pending_icon{
    background: rgba(255, 214, 107, 0.1)
   }
   .completed_icon{
     background: rgba(156, 192, 49, 0.1);
   }
   .failed_icon{
     background: rgba(255, 143, 107, 0.1);
   }
}

@media (min-width: 1280px) {
  .contain {
    max-width: 1280px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1536px) {
  .contain {
    max-width: 1536px;
  }
}



