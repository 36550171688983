.TabHeading{
    display: flex;
    justify-content: center;
}

.activeTab{
    font-weight: 500;
    position: relative;
    cursor: pointer;
    color: #3D3839;
}

.activeTab:after {
    content: "";
    height: 2px;
    width: 100%;
    background: #F47D20;
    position: absolute;
    left: 0;
    bottom: -8px;
    transition: .5s;
}

.tab{
    margin-right: 20px;
    cursor: pointer;
}

.rates_card{
    background: #fff;
    border-radius: 12px;
    padding: 25px;
}

.title{
    text-align: center;
    color: rgba(61, 56, 57, 0.7);
}

.input_container label {
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    font-family: 'BRFirma500';
  }

.input_style{
    border: 1px solid rgba(244, 125, 32, 0.1);
    border-radius: 5px;
    font-size: 14px;
    color: rgba(61, 56, 57, 0.8);
    width: 100%;
    padding: 14px 20px;
    background: #FBFBFB;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input_style:focus{
    border: 1px solid rgba(244, 125, 32, 0.1);
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
.input_style::placeholder{
    color:rgba(26, 55, 78, 0.5);
    font-size: 14px;
    font-family: 'BRFirma400';
}

.btn_add{
    color: #fff;
    border-radius: 6px;
    background: #3D3839;
    padding: 14px 40px;
    transition: all 0.15s ease-in-out;
    width: auto;
    font-weight: 300;
}

.btn_add:hover{
    color: #fff;
    background: rgba(61, 56, 57, 0.9);
}

.ratestables_div{
    background: #fff;
    border-radius: 12px;
    padding: 10px;
}

.goback_div{
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #F47D20;
    text-decoration: none;
}

.goback_div:hover{
    color: #F47D20;
    text-decoration: none;
}


/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {  
    .rates_card{
        background: #fff;
        border-radius: 12px;
        padding: 40px;
    }
}



/* large screens */
@media (min-width: 1536px) {
}